import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Helmet from "react-helmet"

import DefaultLayout from "../components/DefaultLayout"

const Title = styled.h1`
  margin: 0;
`

const Subtitle = styled.p`
  color: #3c4257;
  margin: 0 0 2rem 0;
  font-size: 22px;
`

const SectionRow = styled.div`
  display: flex;
  flex-wrap: wrap;

  > a:first-child {
    margin-left: 0;
  }

  > a:last-child {
    margin-right: 0;
  }
`

const SectionCard = styled(Link)`
  border: 1px solid #e3e8ee;
  border-radius: 4px;
  padding: 1rem;
  transition: box-shadow 0.2s ease-in-out;
  flex-basis: 33%;
  margin: 0 1rem 1rem 0;
  text-decoration: none;

  &:hover {
    box-shadow: 0 0 0 1px rgba(50, 50, 93, 0.01),
      0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.02);
  }


  > p {
    color: #333;
  }
  
`

const SectionTitle = styled.div`
  font-weight: bold;
`

const Home = () => {
  return (
    <DefaultLayout showSidebar={false}>
      <Helmet title={"Morta developer documentation"} />
      <Title>Morta developer documentation</Title>
      <Subtitle>Get up and running with Morta's platform</Subtitle>

      <h2>Setup</h2>

      <SectionRow>
        <SectionCard to={"/quickstart"}>
          <SectionTitle>Development quickstart</SectionTitle>
          <p>Obtain API keys and make a test API request</p>
        </SectionCard>


        <SectionCard to={"https://github.com/morta-technology"}>
          <SectionTitle>Sample integrations</SectionTitle>
          <p>
            Browse some open source examples of how to integrate with the Morta API
          </p>
        </SectionCard>
      </SectionRow>

      <h2>Reference docs</h2>

      <SectionRow>
        <SectionCard to={"/api"}>
          <SectionTitle>API Reference</SectionTitle>
          <p>
            View all backend API endpoints, request schemas, and response
            schemas
          </p>
        </SectionCard>

        <SectionCard to={"/webhooks"}>
          <SectionTitle>Webhooks</SectionTitle>
          <p>
            Listen for events in Morta projects and trigger reactions in your
            app
          </p>
        </SectionCard>

        <SectionCard to={"/permissions"}>
          <SectionTitle>Permissions</SectionTitle>
          <p>
            How to use Morta's permission system to allow specific users access to resources
          </p>
        </SectionCard>

      </SectionRow>

      <h2>Need help?</h2>
      <p>If you have any questions, or have run into an issue with the Morta API, you can contact us at <a href="mailto:support@morta.io">support@morta.io</a></p>

    </DefaultLayout>
  )
}

export default Home
